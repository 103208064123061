import React, { Component } from "react";

class KofiWidget extends Component {
  constructor(props) {
    super(props);
    this.kofiStyle = {
      border: `none`,
      width: 100 + `%`,
      padding: 4 + `px`,
      background: `#f9f9f9`,
    };
  }

  render() {
    return (
      <div className="col-10 mx-auto py-2 shadow-sm p-3 mb-5 bg-dark rounded pb-4">
        <iframe
          id="kofiframe"
          src="https://ko-fi.com/artefaktas/?hidefeed=true&widget=true&embed=true&preview=true"
          style={this.kofiStyle}
          height="712"
          title="artefaktas"
        ></iframe>
      </div>
    );
  }
}

export default KofiWidget;
