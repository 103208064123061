import React, { Component } from "react";

class TextFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      charArray: null,
      wordCount: 1,
      hashtagString: ``,
      mode: 1,
      buttonString: ``,
    };
    this.settings = {
      textarea: {
        inputPlaceholder: `Enter your text here`,
        outputPlaceholder: `Generated text will be shown here`,
        style: {
          width: 100 + `%`,
          height: 18 + `rem`,
        },
      },
      button: {
        buttonText: {
          text: `Hashtagify`,
        },
        approveButtonText: {
          text: `Approve`,
        },
      },
      divArea: {
        style: {
          width: 100 + `%`,
          height: `auto`,
        },
      },
      modeOptions: [
        {
          name: "Automatic",
          value: 1,
        },
        {
          name: "Manual",
          value: 2,
        },
      ],
      options: [
        {
          name: "1",
          value: 1,
        },
        {
          name: "2",
          value: 2,
        },
        {
          name: "3",
          value: 3,
        },
        {
          name: "4",
          value: 4,
        },
        {
          name: "5",
          value: 5,
        },
        {
          name: "6",
          value: 6,
        },
        {
          name: "7",
          value: 7,
        },
        {
          name: "8",
          value: 8,
        },
        {
          name: "9",
          value: 9,
        },
        {
          name: "10",
          value: 10,
        },
      ],
    };
    this.valueChange = this.valueChange.bind(this);
    this.hashtagify = this.hashtagify.bind(this);
    this.wordChange = this.wordChange.bind(this);
    this.generateFromButtons = this.generateFromButtons.bind(this);
  }

  valueChange(t) {
    if (t === "") {
      this.setState({ hashtagString: ``, charArray: t, buttonString: `` });
    } else {
      this.setState({ charArray: t });
    }
  }

  simpleHashtag(words, hashtagifiedString) {
    if (this.state.wordCount !== null && this.state.charArray !== null) {
      words.forEach((element, ind) => {
        element = element.trim();
        let newElement;
        let newInd = ind + 1;
        if (newInd % this.state.wordCount === 0) {
          newElement = `#${element}`;
        } else {
          newElement = element;
        }
        if (newInd !== 1) {
          hashtagifiedString = hashtagifiedString + ` ${newElement}`;
        } else {
          hashtagifiedString = hashtagifiedString + newElement;
        }
      });
    }
    this.setState({ hashtagString: hashtagifiedString });
  }

  buttonEvent() {
    let buttons = document.querySelectorAll(".press");
    buttons.forEach((element) => {
      element.addEventListener("click", (e) => {
        let currentEl = e.currentTarget;
        let text = currentEl.innerText;
        let newText;
        if (!String(text).includes(`#`)) {
          newText = `#${text}`;
        } else {
          newText = String(text).split(`#`)[1];
        }
        currentEl.innerText = ``;
        currentEl.innerText = newText;
      });
    });
  }

  generateFromButtons() {
    let allButtons = document.querySelectorAll(".press");
    let hashtagString = ``;
    let innerTextContent;
    allButtons.forEach((element, ind) => {
      if (ind === 0) {
        innerTextContent = element.innerText;
        hashtagString = hashtagString + innerTextContent;
      } else {
        innerTextContent = ` ${element.innerText}`;
        hashtagString = hashtagString + innerTextContent;
      }
    });
    this.setState({ hashtagString: hashtagString });
  }

  buttonHashtag(words, hashtagifiedString) {
    if (this.state.wordCount !== null && this.state.charArray !== null) {
      words.forEach((element, ind) => {
        element = element.trim();
        let newElement;
        let newInd = ind + 1;
        if (newInd % this.state.wordCount === 0) {
          newElement = `<button class='btn btn-md btn-success press mx-1 my-1'>${element}</button>`;
        } else {
          newElement = element;
        }
        if (newInd !== 1) {
          hashtagifiedString =
            hashtagifiedString +
            ` <button class='btn btn-md btn-success press mx-1 my-1'>${element}</button>`;
        } else {
          hashtagifiedString = hashtagifiedString + newElement;
        }
      });
    }
    this.setState({ buttonString: hashtagifiedString }, () => {
      this.buttonEvent();
    });
  }

  hashtagify() {
    let charAr = this.state.charArray;
    let words = String(charAr).trim().split(" ");
    let hashtagifiedString = ``;
    if (this.state.mode === 1) {
      this.simpleHashtag(words, hashtagifiedString);
    } else {
      this.buttonHashtag(words, hashtagifiedString);
    }
  }

  wordChange(t) {
    this.setState({ wordCount: t });
  }

  modeChange(m) {
    this.setState({ mode: m, buttonString: ``, hashtagString: `` });
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row py-4">
          <div className="col-10 mx-auto py-2 shadow-sm p-3 mb-5 bg-dark rounded pb-4">
            <select
              className="form-control form-control-md my-2"
              defaultValue={this.settings.modeOptions[0].value}
              onChange={(e) => {
                this.modeChange(e.currentTarget.selectedOptions[0].value);
              }}
            >
              {this.settings.modeOptions.map((option, index) => {
                return (
                  <option key={index} value={option.value}>
                    {option.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-10 mx-auto py-2 shadow-sm p-3 mb-5 bg-dark rounded pb-4">
            {parseInt(this.state.mode) !== 2 ? (
              <select
                className="form-control form-control-md my-2"
                defaultValue={this.settings.options[0].value}
                onChange={(e) => {
                  this.wordChange(e.currentTarget.selectedOptions[0].value);
                }}
              >
                {this.settings.options.map((option, index) => {
                  return (
                    <option key={index} value={option.value}>
                      {option.name}
                    </option>
                  );
                })}
              </select>
            ) : (
              <select
                className="form-control form-control-md my-2"
                defaultValue={this.settings.options[0].value}
                onChange={(e) => {
                  this.wordChange(e.currentTarget.selectedOptions[0].value);
                }}
                disabled
              >
                {this.settings.options.map((option, index) => {
                  return (
                    <option key={index} value={option.value}>
                      {option.name}
                    </option>
                  );
                })}
              </select>
            )}

            <textarea
              placeholder={this.settings.textarea.inputPlaceholder}
              style={this.settings.textarea.style}
              className="form-control"
              onInput={(e) => {
                this.valueChange(e.currentTarget.value);
              }}
            ></textarea>
          </div>
          <div className="col-10 mx-auto py-2 shadow-sm p-3 mb-5 bg-dark rounded pb-4">
            <button
              onClick={this.hashtagify}
              className="btn btn-md btn-light d-block mx-auto mt-2"
            >
              {this.settings.button.buttonText.text}
            </button>
          </div>
          {parseInt(this.state.mode) === 2 && this.state.buttonString !== `` ? (
            <>
              <div
                className="col-10 mx-auto py-4 shadow-sm p-3 mb-5 bg-dark rounded pb-4"
                dangerouslySetInnerHTML={{ __html: this.state.buttonString }}
              ></div>
              <div className="col-10 mx-auto py-4 shadow-sm p-3 mb-5 bg-dark rounded pb-4 text-center">
                <button
                  className="btn btn-md btn-light"
                  onClick={this.generateFromButtons}
                >
                  {this.settings.button.approveButtonText.text}
                </button>
              </div>
            </>
          ) : (
            ""
          )}
          <div className="col-10 mx-auto py-2 shadow-sm p-3 mb-5 bg-dark rounded pb-4">
            <textarea
              placeholder={this.settings.textarea.outputPlaceholder}
              style={this.settings.textarea.style}
              className="form-control"
              disabled
              value={this.state.hashtagString}
            ></textarea>
          </div>
        </div>
      </div>
    );
  }
}

export default TextFields;
