import React, { Component } from "react";
import HeaderComponent from "../header/HeaderComponent";
import KofiWidget from "../kofiwidget/KofiWidget";
import TextFields from "../textFields/TextFields";

class MainComponent extends Component {
  componentDidMount() {}

  render() {
    return (
      <div>
        <HeaderComponent />
        <TextFields />
        <KofiWidget />
      </div>
    );
  }
}

export default MainComponent;
