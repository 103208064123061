import React, { Component } from "react";

class HeaderComponent extends Component {
  constructor(props) {
    super(props);
    this.settings = {
      name: `Artefaktas.eu Hashtagify`,
      fontColor: {
        color: `white`,
      },
    };
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row py-4">
          <div className="col-10 mx-auto py-2 shadow-sm p-3 mb-5 bg-dark rounded">
            <h2 style={this.settings.fontColor}>{this.settings.name}</h2>
          </div>
        </div>
      </div>
    );
  }
}

export default HeaderComponent;
